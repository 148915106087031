https://laravel.com/docs/9.x/vite#blade-processing-static-assets
import.meta.glob([
    './bootstrap/**',
    '../../node_modules/**',
    // '../../node_modules/alpinejs/**',
    // '../../node_modules/jquery.initialize/**',
    // '../../node_modules/select2/**',
    // '../../node_modules/@eonasdan/**',
    // '../../node_modules/@ckeditor/**',
]);

import './bootstrap';

import Alpine from 'alpinejs';

import 'jquery.initialize/jquery.initialize';

import 'select2/dist/js/select2';

import '../../../node_modules/@eonasdan/tempus-dominus/dist/css/tempus-dominus.css';
// For production (vite : npm run build)
import * as tempusDominus from '../../../node_modules/@eonasdan/tempus-dominus/dist/js/tempus-dominus.js';
// For local/deveopment (vite : npm run dev)
// import '../../../node_modules/@eonasdan/tempus-dominus/dist/js/tempus-dominus.js';
// import '../../node_modules/@eonasdan/tempus-dominus/dist/js/tempus-dominus.js';
// import '../../node_modules/@eonasdan/tempus-dominus/dist/js/jQuery-provider.js';
// import customDateFormat from '@eonasdan/tempus-dominus/dist/plugins/customDateFormat';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';

import Holidays from 'date-holidays';

import Swal from 'sweetalert2';
window.Swal = Swal;

// import toRupiah from '@develoka/angka-rupiah-js';

import Cleave from 'cleave.js';

import uuid from 'uuid';
window.uuidv4 = uuid;

import nunjucks from 'nunjucks';
window.nunjucks = nunjucks;

import { Calendar } from '@fullcalendar/core'
window.Calendar = Calendar;
import dayGridPlugin from '@fullcalendar/daygrid'
window.dayGridPlugin = dayGridPlugin;
import listPlugin from '@fullcalendar/list'
window.listPlugin = listPlugin;

// Datatables packages
// import jszip from 'jszip';
// window.jszip = jszip;
// import pdfmake from 'pdfmake';
// window.pdfmake = pdfmake;
// import DataTable from 'datatables.net-bs5';
// window.DataTable = DataTable;
// import 'datatables.net-buttons-bs5';
// import '../../../node_modules/datatables.net-bs5/css/dataTables.bootstrap5.min.css';
// import 'datatables.net-buttons/js/buttons.colVis.mjs';
// import '../../../node_modules/datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
// // import '../../../node_modules/datatables.net-buttons/js/dataTables.buttons';
// import 'datatables.net-buttons/js/buttons.html5.mjs';
// import 'datatables.net-buttons/js/buttons.print.mjs';
// import 'datatables.net-colreorder-bs5';
// import DateTime from 'datatables.net-datetime';
// import 'datatables.net-fixedcolumns-bs5';
// import 'datatables.net-fixedheader-bs5';
// import 'datatables.net-responsive-bs5';
// import 'datatables.net-scroller-bs5';
// import 'datatables.net-searchpanes-bs5';

import ApexCharts from 'apexcharts'

window.Alpine = Alpine;

Alpine.start();

Dropzone.autoDiscover = false;

$.initialize('[data-confirm]', function () {
    $(this).on('click', function (e) {
        let $this = $(this);
        let $modal = $('#modal-confirm');

        // If there is alternate modal element, change modal target
        if ($this.data('custom-modal-id') != null && $this.data('custom-modal-id') != undefined) {
            $modal = $('#' + $this.data('custom-modal-id'));
        }

        // Custom script for specific modal only
        let modalType = $this.data('modal-type');
        if (modalType == 'transaction-product-list') {
            // Turn around : to prevent this script run when submit button clicked
            // is_submit as flag for modal is in open or not
            // When is_submit is empty, then run script to refresh modal
            // When is_submit is OK, do not run script. Just submit form anyway
            if ($modal.find('[name=is_submit]').val() == 'OK') {
                return;
            }
            $modal.find('[name=is_submit]').val('OK');

            $modal.on('hidden.bs.modal', function () {
                $modal.find('[name=is_submit]').val('');
            })

            $modal.find('form').attr('action', $this.data('post-url')).trigger('change');
            $modal.find('[name=post_url]').val($this.data('post-url')).trigger('change');

            let $transactionProductId = $modal.find('[name=transaction_product_id]');
            $transactionProductId.val($this.data('transaction-product-id')).trigger('change');

            let $quantity = $modal.find('[name=updated_quantity]');
            $quantity.val($this.data('product-quantity')).trigger('change');

            let $discountType = $('#select2-discount-type');
            $discountType.select2({
                dropdownParent: $modal
            });
            $("#select2-discount-type").val($("#select2-discount-type option:first").val()).trigger('change');

            $('#select2-discount-type > option').each(function() {
                if ($(this).val() == $this.data('product-discount-type')) {
                    $("#select2-discount-type").val($(this).val()).trigger('change');
                }
            });

            let $discountFixed = $modal.find('[id=updated_discount_fixed]');
            $discountFixed.val($this.data('product-discount-fixed') ?? '0').trigger('change');

            let $discountPercentage = $modal.find('[name=updated_discount_percentage]');
            $discountPercentage.val($this.data('product-discount-percentage') ?? '0').trigger('change');
        }

        if (!$this.data('confirmed')) {
            e.preventDefault();
            let title = $this.data('title') || '';
            if (title.length > 0) {
                $modal.find('h4').text(title);
            }
            let message = $this.data('message') || '';
            if (message.length > 0) {
                $modal.find('p').text(message);
            }
            let positiveButtonText = $this.data('positive-button-text') || '';
            if (positiveButtonText.length > 0) {
                $modal.find('button[data-confirm-button="positive"]').text(positiveButtonText);
            }
            let negativeButtonText = $this.data('negative-button-text') || '';
            if (negativeButtonText.length > 0) {
                $modal.find('button[data-confirm-button="negative"]').text(negativeButtonText);
            }
            let icon = $this.data('feather-icon') || '';
            if (icon.length > 0) {
                // Can't change feather icon on demand
                // https://github.com/feathericons/feather/issues/447
                // $(".parent svg.feather.feather-circle").replaceWith(feather.icons.square.toSvg());
            }
            let size = $this.data('feather-size') || '';
            if (size.length > 0) {
                $modal.find('i').addClass('size');
            }
            let positiveButtonColor = $this.data('positive-button-color') || '';
            if (positiveButtonColor.length > 0) {
                $modal.find('button[data-confirm-button=positive]').removeClass('btn-primary btn-secondary btn-info btn-warning btn-danger btn-success').addClass(positiveButtonColor);
            }
            let negativeButtonColor = $this.data('negative-button-color') || '';
            if (negativeButtonColor.length > 0) {
                $modal.find('button[data-confirm-button=negative]').removeClass('text-primary btn-secondary text-info btn-warning btn-danger btn-success').addClass(negativeButtonColor);
            }

            $modal.find('button[data-confirm-button=positive]').on('click', function () {
                $this.data('confirmed', 1);
                $this[0].click();
            });

            $modal.modal('show');
        } else {
            $this.removeData('confirmed');
        }
    });
});

$.initialize('[date-picker=bootstrap]', function () {
    let $this = $(this);

    let date = new Date();
    let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    $(this).datepicker({
        format: "yyyy-mm-dd",
        todayHighlight: true,
        autoclose: true,
        clearBtn: true
    });

    let $existingInputValue = $(this).find('input').eq(0);
    let setDate = $this.data('set-date') || '';
    if (setDate.length > 0 && $existingInputValue.val().length == 0) {
        if (setDate == 'today') {
            $(this).datepicker('setDate', today);
        } else {
            let customDate = new Date(setDate);
            $(this).datepicker('setDate', customDate);
        }
    }
});

$.initialize('[data-toggle=password]', function () {
    let $this = $(this);

    $this.find('a').on('click', function(event) {
        event.preventDefault();
        if ($this.find('input').attr('type') == 'text'){
            $this.find('input').attr('type', 'password');
            $this.find('i').addClass('fa-eye-slash');
            $this.find('i').removeClass('fa-eye');
        } else if ($this.find('input').attr('type') == 'password'){
            $this.find('input').attr('type', 'text');
            $this.find('i').removeClass('fa-eye-slash');
            $this.find('i').addClass('fa-eye');
        }
    });
});

$.initialize('[data-dismiss]', function () {
    $(this).on('click', function (e) {
        e.preventDefault();
        let targetDismiss = $(this).attr('data-dismiss');
        $(this).closest(targetDismiss).remove();
    });
});

$.initialize('[data-library=dropzone]', function () {
    let $this = $(this);
    let token = $this.data('token');
    let url = $this.data('upload-url') || '/dropzone/add';
    let directory = $this.data('upload-directory') || '/others';
    let uploadMultiple = $this.data('upload-multiple') || false;
    let isConvertGrayscale = $this.data('is-convert-grayscale') || false;
    let maxFiles = $this.data('max-files') || 1;
    console.log('max files : ' + maxFiles);

    // Fpr existing
    let fileUrls = $this.data('existing-file-urls') || null;
    let fileNames = $this.data('existing-file-names') || 'File';
    let fileExtensions = $this.data('existing-file-extensions') || '.jpg';
    let fileSizes = $this.data('existing-file-sizes') || '1000000';
    let fileDownloadUrls = $this.data('existing-file-download-urls') || null;

    let currentFilesCount = fileUrls ? 1 : 0;

    // if (maxFiles != null) {
    //     //maxFiles--;
    // }
    // console.log('max files recounted : ' + maxFiles);

    $this.dropzone({
        url: url,
        addRemoveLinks: false,
        uploadMultiple: uploadMultiple,
        maxFiles: maxFiles,
        
        init: function() {
            let myDropzone = this;

            // Populate any existing files
            if (fileUrls) {
                let mockFile = { 
                    name: fileNames, 
                    size: fileSizes,
                    type: 'image/jpeg', 
                    status: Dropzone.ADDED, 
                    url: fileUrls,
                    resizeThumbnail: true
                };
                // Show existing
                myDropzone.displayExistingFile(mockFile, fileUrls);
                // Add existing so it can be treat as an uploaded
                myDropzone.files.push(mockFile);
                // Download link for existing
                let downloadExistingFile = document.createElement('a');
                downloadExistingFile.setAttribute('name', 'dropzone_url');
                downloadExistingFile.setAttribute('href', fileDownloadUrls);
                downloadExistingFile.setAttribute('target', '_blank');
                downloadExistingFile.setAttribute('class', 'w-100 row text-center mx-auto justify-content-center my-auto align-items-center');
                downloadExistingFile.setAttribute('style', 'cursor: pointer;');
                downloadExistingFile.innerHTML = "<br>Download";
                this.files[0].previewTemplate.appendChild(downloadExistingFile);

                // myDropzone.emit("addedfile", mockFile);
                // myDropzone.createThumbnailFromUrl(mockFile, mockFile.url);
                // myDropzone.emit("success", mockFile);
                // myDropzone.emit("complete", mockFile);
                //this.files.length++;
            }

            // console.log('file length : ' + this.files.length);
            // console.log('max files : ' + this.options.maxFiles);

            this.on('sending', function(file, xhr, formData) {
                formData.append('_token', token);
                formData.append('upload_directory', directory);
                formData.append('is_convert_grayscale', isConvertGrayscale);
            });
            this.on('addedfile', function(event) {
                while (this.files.length > this.options.maxFiles) {
                // while (currentFilesCount > maxFiles ) {
                    // Remove recent uploaded file
                    this.removeFile(this.files[0]);
                }
                
            });
            this.on('removedfile', function(event) {
                //console.log('current max file : ' + this.options.maxFiles);
                //this.options.maxFiles++;
                // currentFilesCount--;
                // console.log('removeFIle this.on triggered');
                //console.log('increasing max files');
                //console.log('after max file : ' + this.options.maxFiles);
            });
            this.on('maxfilesexceeded', function(file){
                alert('Maximum file upload reached, please delete if you want to upload new one. maximmum is : ' + this.options.maxFiles);
            });

        },
        success: function (file, response) {
            file.previewElement.classList.add('dz-success');
            if (response != 0){
                $this.siblings('input[type=hidden]').val(response.file_path).trigger('change');
                //this.options.maxFiles--;
                //console.log('decreasing max files');

                // Download link
                let anchorEl = document.createElement('a');
                anchorEl.setAttribute('name', 'dropzone_url');
                anchorEl.setAttribute('href', response.url);
                anchorEl.setAttribute('target', '_blank');
                anchorEl.setAttribute('class', 'w-100 row text-center mx-auto justify-content-center my-auto align-items-center');
                anchorEl.setAttribute('style', 'cursor: pointer;');
                anchorEl.innerHTML = "<br>Download";
                file.previewTemplate.appendChild(anchorEl);
            }
        },
        error: function (file, response) {
            file.previewElement.classList.add('dz-error');
        },
        removedfile: function(file) {
            file.previewElement.remove();
            $this.siblings('input[type=hidden]').val('').trigger('change');
            // maxFiles++;
            // console.log('file removed by function');
        }
    });
});

$.initialize('[color-picker=bootstrap]', function () {
    $(this).colorpicker({
        popover: {
            title: 'Pick a color',
            placement: 'top'
        },
        inline: true,
        container: $(this)
    });
});

$.initialize('[select-picker=select2]', function () {
    let $this = $(this);
    let enableTemplateResult = $this.attr('enable-template-result') || false;

    let options = {};
    if (enableTemplateResult) {
        // Modify rendering result of options
        options.templateResult = formatState;
    }

    $this.select2(options);
});

// Custom function to generate templateResult select2
function formatState (state) {
    if (!state.id) {
        return state.text;
    }
    
    // Rendered format for option element
    let $state = $(
        `<span>${state.text}</span>
        <span style="padding-left: 1em;">
            <div class="card" style="max-width: 1rem; max-height: 1rem;">
                <div class="card-body" style="background-color: ${state.element.dataset.levelColor}; padding: 0.5rem;"></div>
            </div>
        </span>`
    );

    return $state;
};

$.initialize('[datetime-picker=tempus-dominus]', function () {
    let $this = $(this);
    let inputDate = $this.attr('input-date') || 'true';
    let inputHour = $this.attr('input-hour') || 'true';

    let inputFormat = null;
    if (inputDate == 'true') {
        if (inputHour == 'true') {
            inputFormat = 'yyyy-MM-dd HH:mm';
        } else {
            inputFormat = 'yyyy-MM-dd';
        }
    } else {
        if (inputHour == 'true') {
            inputFormat = 'HH:mm';
        } else {
            inputFormat = 'ss';
        }
    }

    $this.siblings('span[data-group=tempus-dominus]').on('click', function (e) {
        e.stopPropagation();
        $(this).prev().trigger('click');
    });

    // Enable custom date format plugin
    // tempusDominus.extend(customDateFormat);

    // Initiate tempus dominus    
    const datetimePicker = new tempusDominus.TempusDominus($this.get(0), {
    // const picker = new TempusDominus($this.get(0), {
    //https://stackblitz.com/edit/tempus-dominus-v6-simple-jquery-setup?file=index.html
    // $this.tempusDominus({
        display: {
            icons: {
                type: 'icons',
                time: 'fa fa-clock-o',
                date: 'fa fa-calendar',
                up: 'fa fa-arrow-up',
                down: 'fa fa-arrow-down',
                previous: 'fa fa-chevron-left',
                next: 'fa fa-chevron-right',
                today: 'fa fa-calendar-check',
                clear: 'fa fa-trash',
                close: 'fa fa-xmark'
            },
            sideBySide: false,
            calendarWeeks: false,
            viewMode: 'calendar',
            toolbarPlacement: 'bottom',
            keepOpen: false,
            buttons: {
                today: false,
                clear: false,
                close: false
            },
            components: {
                calendar: true,
                date: inputDate,
                month: inputDate,
                year: inputDate,
                decades: true,
                clock: inputHour,
                hours: inputHour,
                minutes: inputHour,
                seconds: false,
                useTwentyfourHour: undefined // will be deprecated in the future, so better off
            },
            inline: false,
            theme: 'light'
        },
        // This one need custom date format plugin enabled
        localization: {
            locale: 'id-ID',
            format: inputFormat
        }
    });

    // Initialize holidays from date-holidays library (not included in tempus dominus) indonesia region
    let holidays = new Holidays('ID');

    // Plugin paint to apply custom styling
    datetimePicker.display.paint = (unit, date, classes, element) => {
        if (unit === tempusDominus.Unit.date) {
            // Add class if date is holuday (tanggal merah)            
            if (holidays.isHoliday(new Date(date))) {
                classes.push('special-day'); // We can add custom made css class eg. special-day
            }
        }
    }
});

$.initialize('[text-editor=ckeditor5]', function () {
    ClassicEditor
        .create($(this).get(0))
        .catch( error => {
            console.error( error );
        });
});

function getParameterFromUrl(url, parameter, parameterValue = null) {
    let urlString = new URL(url);
    let urlParamValue = urlString.searchParams.get(parameter);

    if (urlParamValue != undefined && urlParamValue != '') {
        return urlParamValue;
    }

    return false;
}

function searchAjax(url, requestMethod = 'GET', params = []) {
    // let $modalLoading = $('#modal-loading');
    // $modalLoading.modal('show');

    $.ajax({
        url: url,
        type: requestMethod,
        data: params,
        // dataType: 'json',
        beforeSend: function() {
            Swal.fire({
                icon: 'info',
                title: 'Loading',
                text: 'Mengambil data, mohon tunggu...',
                showCancelButton: false,
                showConfirmButton: false
            });
            Swal.showLoading();
        },
        success: function (response) {
            Swal.hideLoading();
            Swal.close();
            // Set rendered html
            // $('#data-index').eq(0).html(response); // trigger bug when using html() so we use js vanilla instead
            document.getElementById('data-index').innerHTML = response;
            // Need to render feather icons
            feather.replace()

            // $modalLoading.hide();
            // $('.modal-backdrop').remove();
        },
        error: function (response) {
            // $modalLoading.hide();
            // $('.modal-backdrop').remove();
            Swal.hideLoading();
            Swal.close();

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: response.message ?? 'Gagal mendapatkan data.'
            })
        }
    });
}

$.initialize('[data-search-type=ajax]', function () {
    let $this = $(this);
    let searchAjaxUrl = $this.closest('table').attr('data-ajax-url');
    
    $this.on('change', function () {
        // Build params
        let params = {};
        $('input[name^=params],select[name^=params]').each(function(index) {
            let paramKey = $(this).attr('data-param-key');
            let paramValue = $(this).val();

            // console.log(paramKey);
            // console.log(paramValue);

            if (paramValue != null && paramValue != undefined && paramValue != '') {
                // params.paramKey = paramValue;
                // params.push({paramKey, paramValue});
                params['params[' +  paramKey + ']'] = paramValue;
            }
        });
        params['response_type'] = 'VIEW';

        let currentUrl = window.location.href;
        if (getParameterFromUrl(currentUrl, 'deleted') == '1') {
            params['deleted'] = 1;
        }

        searchAjax(searchAjaxUrl, 'GET', params);
    });
});

// Bootstrap 5 pagination
$.initialize('a[class=page-link]', function () {
    $(this).on('click', function (e) {
        // Determine if pagination page navigation is view (ajax), not reload page
        let isRequestPaginationView = $('span[data-page-navigation-type=view]');
        if (isRequestPaginationView.length > 0) {
            e.preventDefault();

            let requestUrl = $(this).attr('href');
            searchAjax(requestUrl, 'GET');
        }
    });
});

// Index table sort
$.initialize('[data-sort]', function () {
    $(this).find('a').on('click', function (e) {
        e.preventDefault();

        let requestUrl = $(this).attr('href');
        searchAjax(requestUrl, 'GET');
    });
});

// Index table per page
$.initialize('a[data-per-page]', function () {
    $(this).on('click', function (e) {
        e.preventDefault();

        let requestUrl = $(this).attr('href');
        searchAjax(requestUrl, 'GET');
    });
});

// Input number nominal (idr rupiah)
$.initialize('[data-input=currency]', function () {
    let $this = $(this);
    let cleave = new Cleave($this[0], {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 0
    });
    $this.data('cleave', cleave);
    let target = $this.data('target');

    let $target;
    if (!target) {
        $target = $('<input type="hidden" name="' + $this.attr('name') + '" value="' + cleave.getRawValue() + '">');

        let container = $this.data('container');
        let $container;
        if (!container) {
            $container = $this.closest('form');
        } else {
            $container = $(container);
        }
        if ($container.length > 0) {
            $container.prepend($target);
        }
        $this.removeAttr('name');
    } else {
        $target = $(target);
    }

    $(this).on('keyup', function (e) {
        $target.val(cleave.getRawValue());
    });
    cleave.setRawValue($target.val());
});

$.initialize('[data-render-options]', function () {
    let $this = $(this);

    if ($this.attr('data-render-options') == 'inventory') {
        let $productInventoryOptions = $('#product-inventory-options').children().clone();
        $this.prepend($productInventoryOptions);
    } else if ($this.attr('data-render-options') == 'service') {
        let $productServiceOptions = $('#product-service-options').children().clone();
        $this.prepend($productServiceOptions);
    }

    // Need to render feather icons
    feather.replace()
});

$.initialize('select[data-toggle=target]', function () {
    $(this).on('change', function () {
        $(this).find('option').each(function () {
            let $target = $($(this).data('target'));
            $target.hide();
        });

        if ($(this).prop('multiple')) {
            $(this).find('option:selected').each(function () {
                $($(this).data('target')).show();
            });
        } else {
            let $target = $($(this).find(':selected').data('target'));
            $target.show();
        }
    });

    $(this).trigger('change');
});

// Auto focus search bar on select2 opened
$(document).on('select2:open', (e) => {
    // Vanilla javascript way : https://stackoverflow.com/a/68030102/11143258
    // document.querySelector('.select2-search__field').focus();

    // Jquery way : https://stackoverflow.com/a/72705033/11143258
    const selectName = e.target.name;
    // $(".select2-search__field[aria-controls^='select2-" + selectName).each(function (key, value){
    $(".select2-search__field[aria-controls^='select2-").each(function (key, value){
        value.focus();
    });
});

// Notification mark all
$.initialize('[data-click=notification-mark-all-as-read]', function () {
    $(this).on('click', function (event) {
        event.preventDefault();
        let $this = $(this);
        let url = $this.data('url');
        
        let request = $.ajax({
            url: url,
            type: 'GET',
            data: {},
            // dataType: 'json',
            beforeSend: function() {
                Swal.fire({
                    icon: 'info',
                    title: 'Loading',
                    text: 'Mengirim data, mohon tunggu...',
                    showCancelButton: false,
                    showConfirmButton: false
                });
                Swal.showLoading();
            },
            success: function (response) {
                Swal.hideLoading();
                Swal.close();
            },
            error: function (response) {
                Swal.hideLoading();
                Swal.close();
    
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.message ?? 'Gagal mendapatkan respon.'
                })
            }
        });
    });
});

// Notification mark single
$.initialize('[data-click=notification-mark-as-read]', function () {
    $(this).on('click', function (event) {
        event.preventDefault();
        let $this = $(this);
        let url = $this.data('url');
        
        let request = $.ajax({
            url: url,
            type: 'GET',
            beforeSend: function() {
                Swal.fire({
                    icon: 'info',
                    title: 'Loading',
                    text: 'Mengirim data, mohon tunggu...',
                    showCancelButton: false,
                    showConfirmButton: false
                });
                Swal.showLoading();
            },
            success: function (response) {
                Swal.hideLoading();
                Swal.close();

                // To refresh via ajax search
                $('#data-index').find('select[data-search-type=ajax]').eq(0).trigger('change');
            },
            error: function (response) {
                Swal.hideLoading();
                Swal.close();
    
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.message ?? 'Gagal mendapatkan respon.'
                })
            }
        });
    });
});